<template>
    <div class="modal fade show"  ref="vuemodal"  tabindex="-1" id="quiz_modal" style="display: block; padding-left: 0px;" aria-modal="true" role="dialog">
        <div class="modal-dialog" :class="modelSize">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title quiz-modal-title">{{modaltitle}}</h5>
                    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close">
                        <span class="svg-icon svg-icon-2x cursor-pointer"><svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <rect xmlns="http://www.w3.org/2000/svg" opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                        <rect xmlns="http://www.w3.org/2000/svg" x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg></span>
                    </div>
                </div>
                <slot name="body"></slot>
            </div>
        </div>
    </div>
    <div class="modal-backdrop"/>
</template>

<script>
import { defineComponent, onMounted, onUnmounted } from "vue";

export default defineComponent({
    name: "Modal",
    emits: ["close"],
    props: {
        modaltitle: String,
        modelSize : {
            type : String,
            default : ''
        }
    },
    setup() {
        onMounted(() => {
            document.body.classList.add("custom-overflow");
        });
        onUnmounted(() => {
            document.body.classList.remove("custom-overflow");
        });
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
});
</script>

<style scoped>
.modal-backdrop {
    opacity: 0.2;
}
.cross-button{
    position: absolute;
    top: 12px;
    right: 17px;
}
.model-main-body{
    position: relative;
}
</style>
